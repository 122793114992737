import './mod/avif-webp'
import signature from 'console-signature'
import './mod/contactForm7'
;(function ($) {
    ;('use strict')

    /** Global vars defined by functions.php with 'wp_localize_script' @ script enqueue   **/
    var global = pct_globalVars
    signature()

    const winDim = () => {
        return {
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }



    /*------------------------------------------------------------------------------------------------------*\

							ONLOAD modified for v 3++

	\*------------------------------------------------------------------------------------------------------*/

    window.addEventListener('load', function (event) {
        // console.log(`Script loaded @ --> ${document.location}`)
        this.document.querySelector('body').classList.add('pageloaded')
    })

    document.addEventListener("DOMContentLoaded", function(event) {
        moveSearchIntoHeader()
        setTopBottomShapes()
        //código a ejecutar cuando el DOM está listo para recibir acciones
    });


    const setTopBottomShapes = () => {
        const targetTopShape = document.querySelectorAll('.top-wave')
        const targetBottomShape = document.querySelectorAll('.bottom-wave')
        const topShape = '<svg class="top-wave-shape" data-name="top-wave" viewBox="0 0 1000 99"><path id="top-wave-shape-path" d="M421.9,6.5c22.6-2.5,51.5.4,75.5,5.3,23.6,4.9,70.9,23.5,100.5,35.7C673.8,79.7,731.7,92,790.5,97.2c23.6,2.1,48.7,3.5,103.4-2.5s106.2-25.6,106.2-25.6V0H0V30.3S72,62.9,158.4,60.8c39.2-.7,92.8-6.7,134-22.4,21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5Z"/></svg>'

        if(targetTopShape.length) {
            targetTopShape.forEach((el) => {
              const topDiv = document.createElement('div');
              topDiv.classList.add('top-shape')
              topDiv.innerHTML = topShape
              el.prepend(topDiv)

              if(el.dataset.shapebordercolor){
                el.querySelector('svg').style.fill= el.dataset.shapebordercolor
              }
            })
        }

    }


    /**----------------------- END ONLOAD SECTION ----------------------------------------------------------*/

    const moveSearchIntoHeader = () => {
        if (document.querySelector('#desktop-header-search')) { // Desktop
            const newAfterElement = document.querySelector('#main-wrap header .top-bar-wrap');
            const toMove = document.querySelector('#main-wrap #desktop-header-search');
            newAfterElement.after(toMove)
            addSearchToggle()
            addAutoClose()
        }
        else if (document.querySelector('#main-wrap section.mobile-search-nav')) { // Mobile
            //console.log('mover search para mobile');
            const newAfterElement = document.querySelector('#main-wrap #header-mobile .header-mobile-right > .toggle-button-right')
            const toMove = document.querySelector('#main-wrap section.mobile-search-nav')

            newAfterElement.before(toMove)

            // console.log(newAfterElement, toMove);
        }
    }

    const addAutoClose = () => {
        const elToToggle = document.querySelector('#desktop-header-search')
        const toggler = document.querySelector('#main-wrap li.bt-menu a')

        const closerElement = document.querySelectorAll('#main-wrap > section').forEach( (el) => {

            el.addEventListener('click', (ev) => {
                if(elToToggle.classList.contains('showing')) {
                    //console.log(`hay que cerrar el buscador`);
                    toggler.classList.toggle('clicked')
                    elToToggle.classList.toggle('showing')
                    elToToggle.style.display='none'
                }
            })

        } )
    }

    const addSearchToggle = () => {
        const elToToggle = document.querySelector('#desktop-header-search')
        const toggler = document.querySelector('#main-wrap li.bt-menu a')


        toggler.addEventListener('click', (el) => {
            el.preventDefault()


            if(toggler.classList.contains('clicked')){
                elToToggle.classList.toggle('collapsing')
            }
            else {
                elToToggle.style.display = 'block';
            }

            toggler.classList.toggle('clicked')
            elToToggle.classList.toggle('showing')
        })
        CSSanimationEvents(elToToggle)
    }

    const CSSanimationEvents = (el) => {
        el.addEventListener('animationend', (ev) => {
            if(ev.animationName === 'hideHeaderSearch') {
                el.classList.toggle('collapsing')
                el.style.display = "none"
            }
        })

    }


    /**
       //!AVIF BACKGROUND IMAGES BY CLASS SELECTOR
     *  if not AVIF capabilities, then use a .jpg instead
     * AVIF capabilities checked by avif-webp.js, adding a webp/avif class to <html> tag.
     */
    ;[].slice.call(document.querySelectorAll('[class*="pct-avif-bg-"]')).forEach(function (el) {
        var $el = $(el)
        var fileName = $el
            .attr('class')
            .match(/pct-avif-bg-(.*)+/)[0]
            .split(' ')[0]
            .split('pct-avif-bg-')[1]
        var filePath = global.mediaURL + '/' + fileName + '.' + ($('html').hasClass('avif') ? 'avif' : 'jpg')

        $el.prepend('<div class="pct-bgimg"></div>')
        var $bg = $el.find('.pct-bgimg')
        $bg.css({
            'background-image': 'url(' + filePath + ')',
            'background-size': 'cover',
            'position': 'absolute',
            'top': '0',
            'left': '0',
            'width': '100%',
            'height': '100%',
        })
    })

})(jQuery)
